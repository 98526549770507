/* Table of Content
==================================================
#BASIC TYPOGRAPHY
#HEADER	
#MENU 
#what-includes
#ABOUT
#PRICING TABLE  
#SCREENSHOT
#DOWNLOAD
#how-to-hire
#PRICING TABLE
#how-to-hire
#CONTACT
#FOOTER
#ERROR PAGE
#RESPONSIVE STYLE
*/

/*--------------------*/
/* BASIC TYPOGRAPHY */
/*--------------------*/

body,
html {
  overflow-x: hidden;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  overflow-x: hidden !important;
  color: #838282;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: #2f2f2f;
}

a:focus {
  outline: none;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cabin", sans-serif;
}

h2 {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  margin: 0;
  padding-bottom: 10px;
}

img {
  border: none;
}

.default-button {
  background-color: rgba(255, 115, 29, 255);
  padding: 15px 20px 15px 20px;
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 25px;
  font-size: 15px;
}

.scroll-to-top-button {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  background-color: rgba(255, 115, 29, 255);
  color: white;
  cursor: pointer;
  border: none !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  padding-bottom: 5px;
  border-radius: 5px;
  outline: none !important;
  font-size: 25px;
  font-weight: bold;
}

/* Preloader */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff; /* change if the mask should have another color then white */
  z-index: 99999; /* makes sure it stays on top */
}

#status {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%; /* centers the loading animation horizontally one the screen */
  top: 50%; /* centers the loading animation vertically one the screen */
  background-image: url(../images/newLoader.gif); /* path to your loading animation */
  background-repeat: no-repeat;
  background-position: center;
  margin: -100px 0 0 -100px; /* is width and height divided by two */
}

/*--------------------*/
/* HEADER */
/*--------------------*/

.header-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

#header {
  float: left;
  display: inline;
  width: 100%;
}

.header-inner {
  display: inline;
  position: relative;
  float: left;
  width: 100%;
}

.overlay {
  /* background-color: rgba(0, 0, 0, 0.6); */
  background-color: transparent;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.header-content {
  float: left;
  width: 100%;
  height: 100%;
  text-align: center;
}

.header-top {
  display: inline;
  float: left;
  padding-top: 50px;
  width: 100%;
}

.logo-area {
  display: inline;
  float: left;
  margin-left: -23%;
}

.logo {
  color: #fff;
  font-family: "Pacifico", cursive;
  font-size: 40px;
  font-weight: bold;
}

.logo:hover,
.logo:focus {
  text-decoration: none;
  outline: none;
  color: #fff;
}

.menu-area {
  display: inline;
}

.main-wrap {
  position: relative;
}

.header-bottom {
  display: inline;
  float: left;
  margin-top: 150px;
  width: 100%;
  margin-left: -23% !important;
}

.header-bottom-left {
  margin-top: -10%;
  display: inline;
  float: left;
  padding-top: 60px;
  padding-right: 50px;
  text-align: left;
  width: 100%;
}

.header-bottom-left h1 {
  color: #fff;
  font-size: 80px;
  font-weight: bold;
  line-height: 85px;
  text-transform: none;
  text-shadow: rgba(0, 0, 0, 0.35) 0px 3px 8px !important;
}

.header-bottom-left p {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 5%;
  text-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px !important;
}

.download-btn {
  border-radius: 3px;
  color: #ffffff;
  display: block;
  float: left;
  font-size: 16px;
  height: 48px;
  line-height: 48px;
  margin-top: 30px;
  opacity: 0.8;
  text-align: center;
  width: 180px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.download-btn:hover,
.download-btn:focus {
  text-decoration: none;
  outline: none;
  color: #fff;
  opacity: 1;
}

.header-bottom-right {
  display: inline;
  float: left;
  width: 100%;
}

.header-image {
  height: 100vh;
}

/* Estilos para tamaños de pantalla menores o iguales a 768px */
@media (max-width: 768px) {
  .nav-area {
    justify-content: flex-end;
  }

  .nav-area nav {
    display: none;
  }

  .nav-area .menu-toggle {
    display: block;
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
  }

  .nav-area .menu-toggle:before {
    content: "\2630";
  }

  .nav-area .menu-toggle.active:before {
    content: "\2715";
  }

  .nav-area .responsive-menu {
    display: none;
  }

  .nav-area .responsive-menu.active {
    display: block;
  }

  .nav-area .responsive-menu ul {
    list-style: none;
    padding: 0;
  }

  .nav-area .responsive-menu ul li {
    margin: 10px 0;
  }

  .nav-area .responsive-menu ul li a {
    display: block;
    color: #000;
    text-decoration: none;
  }
}

/*--------------------*/
/* MENU */
/*--------------------*/

.container-fluid {
  padding: 0px;
}

.navbar-hidden {
  transform: translateY(-100%);
  transition: transform 0.3s;
}

#close {
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  height: 20px;
  position: absolute;
  right: 25px;
  text-align: center;
  top: 20px;
  width: 20px;
}

#menu-btn {
  color: #5b7182;
  font-size: 30px;
  position: fixed;
  right: 75px;
  top: 50px;
  z-index: 99;
}

nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 10px;
}

nav ul li {
  margin-right: 20px;
}

nav ul li:last-child {
  margin-right: 30px;
}

nav span {
  color: white;
  font-weight: bold;
  font-family: "Cabin", sans-serif;
}

.fixed-top {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
}

.nav-link {
  color: white !important;
  text-decoration: none !important;
  font-size: 15px !important;
  font-weight: bold !important;
  font-family: "Cabin", sans-serif;
  text-shadow: rgba(149, 157, 165, 0.2) 0px 8px 54px;
  text-align: left;
}

.nav-item a {
  position: relative;
  z-index: 1;
}

.nav-item a:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 115, 29, 255);
  border-radius: 25px;
  z-index: -1;
  padding: 10px !important;
}

.main-nav {
  background-color: #1d252e;
  position: fixed;
  padding-top: 50px;
  width: 260px;
  height: 100%;
  top: 0;
  right: -260px;
  z-index: 1000;
}

.main-nav ul li a {
  color: #5b7182;
  display: block;
  font-size: 16px;
  font-weight: 300;
  padding: 10px 35px;
  text-decoration: none;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.main-nav ul li > a > i {
  padding-right: 10px;
}

.main-nav a:hover,
.main-nav a:focus {
  background: #182029;
}

.main-nav a:active {
  background: #182029;
}

.main-nav.menu-open {
  right: 0px;
}

.cbp-spmenu-push {
  overflow-x: hidden;
  position: relative;
  left: 0;
}

.main-nav,
.cbp-spmenu-push {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*--------------------*/
/* what-includes */
/*--------------------*/

#what-includes {
  background-color: #fff;
  display: inline;
  float: left;
  padding: 70px 0;
  width: 100%;
}

.title-area {
  display: inline;
  float: left;
  padding: 0 150px;
  text-align: center;
  width: 100%;
}

.title-area .title {
  border-bottom: 2px solid #ccc;
  color: #777;
  display: inline-block;
  font-size: 33px;
  font-weight: 700;
  padding-bottom: 20px;
  position: relative;
  text-transform: uppercase;
}

.title-area .title::before {
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  top: 60px;
  width: 150px;
}

.title-area p {
  color: #777777;
  font-size: 19px;
  font-weight: 300;
  line-height: 1.8;
  padding: 20px 0 30px;
}

.what-includes-area {
  display: inline;
  float: left;
  margin-top: 20px;
  width: 100%;
}

.what-includes-left {
  display: inline;
  float: left;
  width: 100%;
}

.what-includes-list li {
  float: left;
  padding: 25px 0;
  position: relative;
}

.what-includes-list li:hover .fa {
  color: #fff;
}

.what-includes-list li i {
  background-color: #777;
  border-radius: 50%;
  color: #fff;
  display: block;
  font-size: 35px;
  height: 90px;
  line-height: 93px;
  margin: 20px auto;
  position: absolute;
  text-align: center;
  top: 10px;
  transition: all 0.5s ease 0s;
  width: 90px;
}

.what-includes-list-left li i {
  left: 0;
}

.what-includes-list-left .what-includes-content {
  padding-left: 115px;
}

.what-includes-content {
  float: left;
  display: inline;
  width: 100%;
}

.what-includes-content h4 {
  font-size: 18px;
  text-transform: uppercase;
  text-align: left;
}

.what-includes-content p {
  font-size: 15px;
  text-align: left;
}

.feature-img {
  display: inline;
  float: left;
  padding: 0 30px;
  text-align: center;
  width: 100%;
}

.feature-img img {
  width: 100%;
}

.what-includes-right {
  display: inline;
  float: left;
  width: 100%;
}

.what-includes-list-right li i {
  right: 0;
}

.what-includes-list-right .what-includes-content {
  padding-right: 115px;
}

/*--------------------*/
/* ABOUT */
/*--------------------*/

#about {
  background-attachment: fixed !important;
  background-image: url("../images/whatis.jpg");
  /* background-color: grey; */
  background-repeat: repeat;
  background-size: cover;
  color: #ffffff;
  display: inline;
  float: left;
  padding: 70px 0;
  width: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

/* .about-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 25px;

} */

.about-area .title {
  color: #fff;
}

.about-area .title-area p {
  color: #fff;
}

.about-video {
  background: rgba(0, 0, 0, 0) url("../images/macbook-pro.png") no-repeat scroll
    center 100% / 100% auto;
  margin: 0 auto;
  max-width: 700px;
  padding: 70px 0 60px;
  position: relative;
  z-index: 10;
}

.about-video iframe {
  border: medium none;
  margin-bottom: 20px;
  /* margin-left: 5px; */
  width: 428px;
}

/*--------------------*/
/* PRICING TABLE */
/*--------------------*/

.single-plan-content ul {
  column-count: 2;
}

#packs {
  display: inline;
  float: left;
  width: 100%;
  padding: 70px 0;
}

.packs-content {
  display: inline;
  float: left;
  margin-top: 20px;
  width: 100%;
}

.single-plan {
  display: inline;
  float: left;
  width: 100%;
  background-color: #f0f0f0;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.single-plan:hover {
  box-shadow: 0 2px 20px #ccc;
}

.feature-price .single-plan-head p > span {
  color: #fff;
}

.single-plan-head span {
  color: rgb(255, 115, 29);
}

.single-plan-head {
  background-color: #424242;
  display: inline;
  float: left;
  padding: 10px 5px;
  text-align: center;
  width: 100%;
}

.single-plan-head h5 {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 0;
  padding: 5px;
  text-transform: uppercase;
}

.single-plan-head p {
  color: #fff;
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
}

.single-plan-head p > span {
  font-weight: normal;
  font-size: 15px;
}

.single-plan-content {
  display: inline;
  float: left;
  width: 100%;
  padding: 0 15px;
}

.single-plan-content ul {
  text-align: center;
}

.single-plan-content ul li {
  border-bottom: 1px solid #fff;
  padding: 15px 10px;
  font-size: 13px;
  height: 60px;
}

.single-plan-content ul li.delete {
  text-decoration: line-through;
}

.single-plan-content ul li:last-child {
  border: none;
}

.single-plan-bottom {
  display: inline;
  float: left;
  padding: 15px 15px 30px;
  text-align: center;
  width: 100%;
}

.single-plan-bottom .signup-btn {
  border-radius: 3px;
  border: 1px none transparent;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  opacity: 0.8;
  padding: 15px 50px;
  text-decoration: none;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.single-plan-bottom .signup-btn:hover {
  border-color: 1px solid #fff;
  opacity: 1;
}

/*--------------------*/
/* SCREENSHOT */
/*--------------------*/

#models {
  background-color: #212121;
  display: inline;
  float: left;
  padding: 70px 0;
  width: 100%;
}

#models .title,
#models .title-area p {
  color: #fff;
}

.models-img {
  margin: 0 auto;
  border: 5px solid white;
}

.view-btn {
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.black-hover {
  position: relative;
  display: inline-block;
}

.black-hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease; /* Transición de opacidad */
}

.black-hover:hover::before {
  opacity: 1; /* Opacidad completa al hacer hover */
}

.zoom-icon {
  position: absolute;
  top: 88%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.model-button {
  background-color: rgba(255, 115, 29, 255);
  padding: 10px 20px 10px 20px;
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 25px;
  font-size: 13px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.model-button:hover {
  background-color: transparent;
  border: 2px solid white;
}

/*--------------------*/
/* CONTACT */
/*--------------------*/

.wsp-logo {
  width: 25px;
  margin-top: -3px;
}

.contact-button {
  background-color: rgba(255, 115, 29, 255);
  padding: 15px 20px 15px 20px;
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 25px;
  font-size: 15px;
}

.contact-button:hover {
  border: 2px solid white;
  background-color: transparent;
}

#contact {
  background-attachment: fixed !important;
  background-image: url("./../images/contact-bg-2.jpg");
  background-repeat: repeat;
  background-size: cover;
  color: #ffffff;
  display: inline;
  float: left;
  width: 100%;
}

.download-overlay {
  background: rgba(15, 22, 30, 0.8) none repeat scroll 0 0;
  color: #ffffff;
  display: inline;
  float: left;
  padding: 90px 0;
  width: 100%;
}

#contact .title,
#contact .title-area p {
  color: #fff;
}

.contact-content {
  display: inline;
  float: left;
  width: 100%;
  text-align: center;
}

.single-download {
  border: 2px solid #fff;
  border-radius: 4px;
  display: inline-block;
  margin: 0 15px;
  padding: 18px 25px;
  width: 25%;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.download-icon {
  display: inline;
  float: left;
  width: 60px;
  color: #fff;
}

.download-icon i {
  font-size: 40px;
  margin-top: 5px;
}

.download-content {
  display: inline;
  float: left;
  text-align: left;
  margin-left: 5px;
}

.download-content p {
  color: #f8f8f8;
  font-size: 15px;
  margin-bottom: 3px;
}

.download-content span {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

/*--------------------*/
/* how-to-hire */
/*--------------------*/

#how-to-hire {
  background-color: #fff;
  display: inline;
  float: left;
  padding: 70px 0;
  width: 100%;
}

.how-to-hire-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  width: 50%;
  margin: 0 auto;
}

.how-to-hire-single-slide {
  display: inline;
  float: left;
  padding: 25px 0px 20px;
  text-align: center;
  width: 100%;
}

.testi-name {
  color: white;
  background-color: rgba(255, 115, 29, 0.9);
  border-radius: 50%;
  font-size: 48px;
  letter-spacing: 1px;
  margin: 10px 0 0;
  text-align: center;
  text-transform: uppercase;
  font-family: "Cabin", sans-serif;
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin: 0 auto;
}

.testi-company {
  font-size: 34px;
  font-weight: bold;
  font-family: "Cabin", sans-serif;
  text-align: center;
  color: #777;
  margin-top: 20px;
}

.testi-text {
  color: #777777;
  font-size: 19px;
  font-weight: 300;
  line-height: 1.8;
  padding: 20px 0 30px;
}

.testi-text:before {
  content: "\f10d";
  position: absolute;
  font-family: fontAwesome;
  left: -10px;
  top: -20px;
}

.how-to-hire-slider .slick-dots {
  bottom: 15px;
  margin-left: -40px;
}

.slick-dots {
  margin: 0 auto !important;
}

.how-to-hire-slider .slick-dots li {
  background: #ccc none repeat scroll 0 0;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  height: 8px;
  margin: 0 5px;
  padding: 0;
  position: relative;
  width: 8px;
}

.how-to-hire-slider .slick-dots li.slick-active {
  opacity: 1;
}

.how-to-hire-slider .slick-dots li button::before {
  display: none;
}

/*--------------------*/
/* FOOTER */
/*--------------------*/

#footer {
  /* background-color: #1d252e; */
  display: inline;
  float: left;
  padding: 40px 0;
  width: 100%;
}

.footer-social {
  display: inline;
  float: left;
  width: 100%;
  text-align: center;
}

.footer-social a {
  display: inline-block;
  color: #fff;
  font-size: 18px;
  margin: 5px;
}

.footer-text {
  display: inline;
  float: left;
  padding: 10px 0;
  width: 100%;
  text-align: center;
}

.footer-text p {
  margin-bottom: 0;
  font-size: 13px;
}

.footer-text p a {
  color: rgba(255, 115, 29, 0.9);
  font-weight: bold;
  text-decoration: none;
}

/*--------------------*/
/* ERROR PAGE */
/*--------------------*/

#error {
  display: inline;
  float: left;
  width: 100%;
}

.error-area {
  display: inline;
  float: left;
  padding: 150px 0;
  text-align: center;
  width: 100%;
}

.error-group {
  display: inline;
  float: left;
  width: 100%;
}

.error-group h1 {
  color: #979797;
  font-size: 80px;
  margin-bottom: 25px;
}

.error-group h1 span {
  border: 1px solid #cccccc;
  display: inline-block;
  margin-left: 5px;
  overflow: hidden;
  padding: 2px;
}

.error-group h1 span strong {
  border: 1px solid #cccccc;
  display: inline-block;
  font-weight: normal;
  padding: 15px 20px;
}

.error-group h2 {
  color: #979797;
  font-size: 60px;
  text-align: center;
  text-transform: uppercase;
  line-height: 55px;
}

.error-group h2 span {
  display: block;
  font-size: 30px;
}

.home-btn a {
  text-decoration: none;
  outline: none;
}

/*======================///////////////
			START RESPONSIVE STYLE
=====================///////////////////////*/

@media (max-width: 1199px) {
  .header-bottom {
    margin-top: 50px;
  }
  .feature-img {
    padding: 0;
  }
  .what-includes-list li {
    padding: 10px 0;
  }
  .what-includes-list li i {
    top: 0px;
  }
  .single-download {
    padding: 18px 10px;
  }
  #menu-btn {
    right: 50px;
  }
}

@media (max-width: 991px) {
  .header-bottom {
    margin-top: 60px;
  }
  .header-bottom-right img {
    width: 100%;
  }
  .header-bottom-left h1 {
    font-size: 32px;
    line-height: 45px;
  }
  .what-includes-list li {
    margin-bottom: 20px;
  }
  .feature-img img {
    width: auto;
  }
  .what-includes-list-right li i {
    left: 0;
    right: auto;
  }
  .what-includes-list-right .what-includes-content {
    padding-left: 115px;
    padding-right: 0;
  }
  .what-includes-left {
    margin-bottom: 30px;
  }
  .what-includes-right {
    margin-top: 30px;
  }
  .what-includes-list li i {
    font-size: 25px;
    height: 70px;
    line-height: 70px;
    width: 70px;
  }
  .title-area {
    padding: 0 50px;
  }
  .single-plan {
    margin-bottom: 25px;
  }
  .single-download {
    margin: 0 8px;
    padding: 18px 5px;
    width: 30%;
  }
  .subcr-form {
    width: 95%;
  }
  .contact-area {
    padding: 0 100px;
  }
}

@media (max-width: 767px) {
  .header-bottom {
    margin-top: 0;
  }
  .header-bottom-left {
    padding-right: 0;
    padding-top: 0;
    margin-top: 10px;
  }
  .header-bottom-left h1 {
    font-size: 25px;
    line-height: 30px;
  }
  .header-bottom-left p {
    margin: 10px 0 0;
  }
  .download-btn {
    margin-top: 20px;
    width: 160px;
  }
  .header-bottom-right img {
    width: 45%;
  }
  .title-area {
    padding: 0 10px;
  }
  .about-video {
    background: none;
    text-align: center;
  }
  .about-video iframe {
    display: inline-block;
    margin-left: auto;
    width: 90%;
  }
  .slick-prev {
    left: 6px;
    z-index: 999;
  }
  .slick-next {
    right: 5px;
  }
  .download-icon i {
    font-size: 35px;
  }
  .download-icon {
    width: 45px;
  }
  .download-content p {
    font-size: 13px;
  }
  .download-content span {
    font-size: 15px;
  }
  .contact-area {
    padding: 0 50px;
  }
}

@media (max-width: 480px) {
  .main-nav {
    padding-top: 10px;
    width: 200px;
  }
  .main-nav ul li a {
    font-size: 14px;
    padding: 8px 35px;
  }
  .header-bottom-right {
    margin-top: 30px;
  }
  .header-bottom-left {
    padding-right: 0;
    padding-top: 0;
    margin-top: 10px;
  }
  .header-bottom-left h1 {
    font-size: 25px;
    line-height: 30px;
  }
  .header-bottom-left p {
    margin: 10px 0 0;
  }
  .download-btn {
    margin-top: 20px;
    width: 160px;
  }
  .header-bottom-right img {
    width: 50%;
  }
  .title-area {
    padding: 0;
  }
  .about-video {
    background: none;
    text-align: center;
  }
  .about-video iframe {
    display: inline-block;
    margin-left: auto;
    width: 90%;
  }
  .slick-prev {
    left: 6px;
    z-index: 999;
  }
  .slick-next {
    right: 5px;
  }
  .single-download {
    margin: 0 8px 20px;
    width: 100%;
  }
  .download-icon {
    width: 60px;
  }
  .download-icon i {
    font-size: 40px;
  }
  .download-content p {
    font-size: 15px;
  }
  .download-content span {
    font-size: 18px;
  }
  .contact-area {
    padding: 0 50px;
  }
  .title-area .title {
    font-size: 25px;
    padding-bottom: 10px;
  }
  .title-area .title::before {
    top: 50px;
  }
  .view-btn {
    margin-right: 25%;
  }
}

@media (max-width: 360px) {
  .header-bottom-left h1 {
    font-size: 20px;
    line-height: 25px;
  }
  .header-bottom-left p {
    font-size: 14px;
  }
  .download-btn {
    font-size: 15px;
    width: 150px;
  }
  .header-bottom-right {
    margin-top: 60px;
  }
  .header-bottom-right img {
    width: 60%;
  }
  .title-area .title {
    font-size: 20px;
  }
  .title-area .title::before {
    width: 85px;
  }

  .title-area p {
    font-size: 14px;
  }
  .what-includes-content h4 {
    font-size: 16px;
  }
  .what-includes-content p {
    font-size: 14px;
  }
  .what-includes-list-left .what-includes-content {
    padding-left: 95px;
  }
  .what-includes-list-right .what-includes-content {
    padding-left: 95px;
  }
  .feature-img img {
    width: 85%;
  }
  .contact-content {
    width: 95%;
  }
  .single-download {
    padding: 18px 30px;
  }
  .how-to-hire-slider {
    padding: 40px 10px;
    width: 100%;
  }
  .testi-text {
    font-size: 18px;
    margin-top: 20px;
  }
  .subcr-form {
    width: 100%;
  }
  .subcr-form i {
    font-size: 25px;
    left: 10px;
    top: 14px;
  }
  .subcr-form input[type="text"] {
    font-size: 14px;
    height: 55px;
    padding-left: 40px;
    padding-right: 105px;
  }
  .subcr-form .subcr-btn {
    font-size: 15px;
    height: 55px;
    width: 100px;
  }
  .contact-area {
    padding: 0;
  }
  .how-to-hire-slider .slick-dots {
    bottom: 15px;
    margin-left: -10px;
  }
  .single-plan-head h5 {
    font-size: 25px;
  }
  .single-plan-head p {
    font-size: 20px;
    line-height: 25px;
  }
  .single-plan-content ul li {
    font-size: 15px;
  }
  .single-plan-bottom .signup-btn {
    font-size: 16px;
    padding: 13px 35px;
  }
  .footer-text p {
    font-size: 14px;
  }
  .view-btn {
    margin-right: 35%;
  }
  .error-area {
    padding: 100px 0;
  }
  .error-group h1 {
    font-size: 65px;
  }
  .error-group h2 {
    font-size: 55px;
    line-height: 50px;
  }
}

.nav-link {
  text-transform: uppercase;
}

.about-video iframe {
  width: 428px;
  height: 276px;
}

/* start my own media queries*/

@media only screen and (min-width: 0px) and (max-width: 320px) {
  .logo-area {
    display: none;
  }
  .header-web {
    display: none;
  }
  .header-bottom {
    display: inline;
    float: left;
    margin-top: -25%;
    width: 100%;
    margin-left: 8% !important;
  }

  .header-bottom-left {
    display: inline;
    float: left;
    padding-top: 60px;
    padding-right: 50px;
    text-align: center;
    width: 100%;
  }

  .default-button {
    margin-top: 350px;
  }

  .header-bottom-left h1 {
    color: #fff;
    font-size: 47px;
    font-weight: bold;
    line-height: 60px;
    text-transform: none;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 5%;
  }

  .header-bottom-left p {
    color: #fff;
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 15%;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .nav-link {
    text-transform: uppercase;
  }

  .navbar-nav {
    margin-left: 20px !important;
  }

  .navbar {
    padding: 0px;
  }

  .navbar-toggler {
    margin-top: 10px;
    margin-left: 5px;
    border: 1px solid transparent;
  }

  .navbar-toggler-icon {
    color: red !important;
  }

  .navbar-collapse {
    background-color: rgba(255, 115, 29, 0.9);
  }

  .collapsed {
    border: 1px solid transparent;
  }

  .how-to-hire-slider {
    width: 100%;
  }
  .about-video iframe {
    width: 300px;
    height: 300px;
    margin-top: -20%;
  }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  .logo-area {
    display: none;
  }
  .header-web {
    display: none;
  }
  .header-bottom {
    display: inline;
    float: left;
    margin-top: -25%;
    width: 100%;
    margin-left: 8% !important;
  }

  .header-bottom-left {
    display: inline;
    float: left;
    padding-top: 60px;
    padding-right: 50px;
    text-align: center;
    width: 100%;
  }

  .default-button {
    margin-top: 410px;
  }

  .header-bottom-left h1 {
    color: #fff;
    font-size: 47px;
    font-weight: bold;
    line-height: 60px;
    text-transform: none;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 5%;
  }

  .header-bottom-left p {
    color: #fff;
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 15%;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .how-to-hire-slider {
    width: 100%;
  }

  .nav-link {
    text-transform: uppercase;
  }

  .navbar-nav {
    margin-left: 20px !important;
  }

  .navbar {
    padding: 0px;
  }

  .navbar-toggler {
    margin-top: 10px;
    margin-left: 5px;
    border: 1px solid transparent;
  }

  .navbar-toggler-icon {
    color: red !important;
  }

  .navbar-collapse {
    background-color: rgba(255, 115, 29, 0.9);
  }

  .collapsed {
    border: 1px solid transparent;
  }

  .how-to-hire-slider {
    width: 100%;
  }
  .about-video iframe {
    width: 320px;
    height: 320px;
    margin-top: -15%;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .logo-area {
    display: none;
  }
  .header-web {
    display: none;
  }
  .header-bottom {
    display: inline;
    float: left;
    margin-top: -15%;
    width: 100%;
    margin-left: 8% !important;
  }

  .header-bottom-left {
    display: inline;
    float: left;
    padding-top: 60px;
    padding-right: 50px;
    text-align: center;
    width: 100%;
  }

  .default-button {
    margin-top: 400px;
  }

  .header-bottom-left h1 {
    color: #fff;
    font-size: 50px;
    font-weight: bold;
    line-height: 60px;
    text-transform: none;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 5%;
  }

  .header-bottom-left p {
    color: #fff;
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 15%;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .how-to-hire-slider {
    width: 100%;
  }

  .nav-link {
    text-transform: uppercase;
  }

  .navbar-nav {
    margin-left: 20px !important;
  }

  .navbar {
    padding: 0px;
  }

  .navbar-toggler {
    margin-top: 10px;
    margin-left: 5px;
    border: 1px solid transparent;
  }

  .navbar-toggler-icon {
    color: red !important;
  }

  .navbar-collapse {
    background-color: rgba(255, 115, 29, 0.9);
  }

  .collapsed {
    border: 1px solid transparent;
  }

  .how-to-hire-slider {
    width: 100%;
  }
  .about-video iframe {
    width: 370px;
    height: 370px;
    margin-top: -15%;
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  .invitapp-logo {
    width: 25%;
    margin-bottom: -10%;
  }
  .logo-area {
    display: inline;
    float: left;
    margin-left: -40%;
  }
  .header-mobile {
    display: none;
  }

  .header-bottom {
    display: inline;
    float: left;
    margin-top: 15%;
    width: 100%;
    margin-left: 5% !important;
  }

  .header-bottom-left {
    display: inline;
    float: left;
    padding-top: 60px;
    padding-right: 50px;
    text-align: left;
    width: 60%;
  }

  .header-bottom-left h1 {
    color: #fff;
    font-size: 60px;
    font-weight: bold;
    line-height: 65px;
    text-transform: none;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 5%;
  }

  .header-bottom-left p {
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 15%;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .how-to-hire-slider {
    width: 100%;
  }

  .nav-link {
    text-transform: uppercase;
  }

  .navbar-nav {
    margin-left: 20px !important;
  }

  .navbar {
    padding: 0px;
  }

  .navbar-toggler {
    margin-top: 10px;
    margin-left: 5px;
    border: 1px solid transparent;
  }

  .navbar-toggler-icon {
    color: red !important;
  }

  .navbar-collapse {
    background-color: rgba(255, 115, 29, 0.9);
  }

  .collapsed {
    border: 1px solid transparent;
  }

  .how-to-hire-slider {
    width: 100%;
  }
  .about-video iframe {
    width: 428px;
    height: 276px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .menu-icons {
    display: none;
  }
  .invitapp-logo {
    width: 22%;
    margin-bottom: -7%;
  }
  .logo-area {
    display: inline;
    float: left;
    margin-left: -25%;
  }
  .header-mobile {
    display: none;
  }

  .header-bottom {
    display: inline;
    float: left;
    margin-top: 10%;
    width: 100%;
    margin-left: 5% !important;
  }

  .header-bottom-left {
    display: inline;
    float: left;
    padding-top: 60px;
    padding-right: 50px;
    text-align: left;
    width: 50%;
  }

  .header-bottom-left h1 {
    color: #fff;
    font-size: 60px;
    font-weight: bold;
    line-height: 65px;
    text-transform: none;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .header-bottom-left p {
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 7%;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .menu-icons {
    display: none;
  }
  .invitapp-logo {
    width: 28%;
    margin-bottom: -14%;
  }
  .logo-area {
    display: inline;
    float: left;
    margin-left: -12%;
  }
  .header-mobile {
    display: none;
  }
  .header-bottom {
    display: inline;
    float: left;
    margin-top: 15%;
    width: 100%;
    margin-left: 5% !important;
  }

  .header-bottom-left {
    display: inline;
    float: left;
    padding-top: 60px;
    padding-right: 50px;
    text-align: left;
    width: 50%;
  }

  .header-bottom-left h1 {
    color: #fff;
    font-size: 75px;
    font-weight: bold;
    line-height: 85px;
    text-transform: none;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .header-bottom-left p {
    color: #fff;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 7%;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1920px) {
  .menu-icons {
    display: none;
  }
  .invitapp-logo {
    width: 30%;
  }
  .logo-area {
    display: inline;
    float: left;
    margin-left: -22%;
  }
  .header-mobile {
    display: none;
  }
  .header-bottom {
    display: inline;
    float: left;
    margin-top: 6%;
    width: 100%;
    margin-left: -5% !important;
  }

  .header-bottom-left {
    display: inline;
    float: left;
    padding-top: 60px;
    padding-right: 50px;
    text-align: left;
    width: 50%;
  }

  .header-bottom-left h1 {
    color: #fff;
    font-size: 75px;
    font-weight: bold;
    line-height: 85px;
    text-transform: none;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .header-bottom-left p {
    color: #fff;
    font-size: 27px;
    font-weight: 500;
    margin-bottom: 4%;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

/* additonal media queries */

/* @media only screen and (min-width: 412px) and (max-width: 426px) {
  .default-button {
    margin-top: 105%;
  }

  .header-bottom {
    display: inline;
    float: left;
    margin-top: -13%;
    width: 100%;
    margin-left: 8% !important;
  }
} */

/* end my own media queries*/

.big-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.big-container-item {
  flex-basis: calc(25% - 10px);
  background-color: lightgray;
  padding-bottom: calc(25% - 10px);
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .item {
    flex-basis: 100%;
    padding-bottom: calc(100% - 10px);
  }
}
